import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FilledInput from '@mui/material/FilledInput';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Tab from '@mui/material/Tab';
import Tooltip from '@mui/material/Tooltip';
import moment from 'moment';
import { startTransition, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';

import Menu from '@mui/material/Menu';
import images from '../../assets/images';
import AddMemberToBmeetDialog from '../../components/dialog/bmeet-member-dialog/AddMemberToBmeetDialog';
import instance from '../../shared/interceptor';
import { getCurrentSpaceDetail } from '../../shared/services/common.service';
import LoaderComp from '../../shared/shared-comp/LoaderComp';
import OverflowTooltip from '../../shared/shared-comp/OverflowTooltip';
import { currentSpaceState } from '../../shared/store/slices/companySlice';
import { setSuccessMsg } from '../../shared/store/slices/successSlice';
import {
  companyState,
  permissionState,
  userState,
} from '../../shared/store/slices/userSlice';
import {
  ON_BOARDING_WAITING_PAYMENT,
  SPACES,
  SPACE_LIST,
} from '../../shared/util/constant';
import {
  formatDate,
  getFullname,
  getInitials,
  globalError,
  isAllowedTo,
  isPrivateSpaceOwner,
  isUserCioOrSpaceMember,
  setLoader,
  sortByDate,
  sortProject,
  transNavigation,
} from '../../shared/util/util';
import './_buildingSpaces.scss';
import RightPageHeader from '../../shared/shared-comp/RightPageHeader';
import UnauthorizedAccess from '../../components/UnauthorizedAccess';

function SpaceIdeaboard() {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const compSel = useSelector(companyState);
  const userData = useSelector(userState);
  const currentSpaceSel = useSelector(currentSpaceState);
  const permissions = useSelector(permissionState);

  const [allMeetboard, setAllMeetboard] = useState(null);
  const [searchAllMeetboard, setSearchAllMeetboard] = useState(null);
  const [meetings, setMeetings] = useState(null);
  const [searchMeetings, setSearchMeetings] = useState(null);
  const [whiteBoards, setWhiteBoards] = useState(null);
  const [selectedBoard, setSelectedBoard] = useState(null);
  const [searchedBoard, setSearchedBoard] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [sortAsc, setSortAsc] = useState('created');
  const [loading, setLoading] = useState(true);
  const [ideaboardTabValue, setIdeaboardTabValue] = useState('All');
  const [callMyTeam, setCallMyTeam] = useState(false);
  const [showEmptyState, setShowEmptyState] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);

  let userColors = [`#E1C3FF`, `#FFC7B8`, `#C8F8FF`, `#ECEDF0`];

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    getCurrentSpaceDetail(dispatch, compSel?.id, params?.id);
    getListOfIdeaboard(signal);
    return () => {
      controller.abort();
    };
  }, [params?.id]);

  const closeDeleteWhiteboardDialog = () => {
    setSelectedBoard(null);
  };

  const getListOfIdeaboard = async (signal?, loading = true) => {
    try {
      if (loading) {
        setLoading(true);
      }
      const url = `${SPACES.replace(':id', compSel?.id)}/${params?.id}/ideaboard_data`;
      let res = await instance.get(url, { signal });
      let temp = res?.['meetings'].filter((item) => item?.past);
      if (res?.['whiteboards']) {
        setAllMeetboard(
          sortByDate(res['whiteboards'].concat(temp), 'created_at', 'Latest')
        );
        setSearchAllMeetboard(
          sortByDate(res['whiteboards'].concat(temp), 'created_at', 'Latest')
        );
        setWhiteBoards(sortByDate(res['whiteboards'], 'created_at', 'Latest'));
        setSearchedBoard(
          sortByDate(res['whiteboards'], 'created_at', 'Latest')
        );
      }
      if (res?.['meetings']) {
        setMeetings(sortByDate(temp, 'created_at', 'Latest'));
        setSearchMeetings(sortByDate(temp, 'created_at', 'Latest'));
      }
      if (!res?.['whiteboards']?.length) {
        setShowEmptyState(true);
      }
    } catch (e) {
      globalError(dispatch, e);
    } finally {
      setLoading(false);
    }
  };

  const deleteBoard = async () => {
    try {
      setLoader(dispatch, true);
      const url = `${SPACES.replace(':id', compSel?.id)}/${params?.id}/whiteboards/${selectedBoard?.id}?name=${selectedBoard?.name}`;
      await instance.delete(url);
      dispatch(setSuccessMsg('Whiteboard deleted successfully.'));
      getListOfIdeaboard(null, false);
      closeDeleteWhiteboardDialog();
    } catch (e) {
      globalError(dispatch, e);
    } finally {
      setLoader(dispatch, false);
    }
  };

  const searchWhiteBoard = (value) => {
    setSearchText(value);
    if (!value) {
      return resetValues();
    }
    let filteredItems;
    if (ideaboardTabValue == 'All') {
      filteredItems = searchAllMeetboard.filter(
        (item) =>
          item?.name?.toLowerCase().includes(value?.toLowerCase()) ||
          item?.updated_by_name?.toLowerCase().includes(value?.toLowerCase())
      );
      setAllMeetboard(filteredItems);
    } else if (ideaboardTabValue == 'Meetings') {
      filteredItems = searchMeetings.filter(
        (item) =>
          item?.name?.toLowerCase().includes(value?.toLowerCase()) ||
          item?.updated_by_name?.toLowerCase().includes(value?.toLowerCase())
      );
      setMeetings(filteredItems);
    } else {
      filteredItems = searchedBoard.filter(
        (item) =>
          item?.name?.toLowerCase().includes(value?.toLowerCase()) ||
          item?.updated_by_name?.toLowerCase().includes(value?.toLowerCase())
      );
      setWhiteBoards(filteredItems);
    }
  };

  const getTabValToSetData = () => {
    if (ideaboardTabValue == 'All') {
      return [allMeetboard, setAllMeetboard];
    } else if (ideaboardTabValue == 'Meetings') {
      return [meetings, setMeetings];
    } else {
      return [whiteBoards, setWhiteBoards];
    }
  };

  const sortData = (value) => {
    setSortAsc(value);
    let setDataVal = getTabValToSetData();
    if (value == 'A-Z' || value == 'Z-A') {
      setDataVal[1](sortProject({ target: { value } }, setDataVal[0]));
    } else if (value == 'created') {
      setDataVal[1](sortByDate(setDataVal[0], 'created_at', 'Latest'));
    } else if (value == 'updated') {
      setDataVal[1](sortByDate(setDataVal[0], 'updated_at', 'Latest'));
    }
  };

  const isCioOrSpaceMember = isUserCioOrSpaceMember(userData, currentSpaceSel);

  const handleClick = (event, idx) => {
    setAnchorEl({ [idx]: event.currentTarget });
  };

  const getWbUrl = async () => {
    try {
      if (!isCioOrSpaceMember) {
        return globalError(dispatch, {
          message: 'You are not the owner/member of the space',
        });
      }
      setLoader(dispatch, true);
      const url = `${SPACES.replace(':id', compSel?.id)}/${currentSpaceSel?.id}/whiteboards`;
      let res = await instance.post(url, null);
      navigate(
        `/company/spaces/${currentSpaceSel?.id}/whiteboard-playground/${res?.['whiteboard']?.id}`
      );
    } catch (e) {
      globalError(dispatch, e);
    } finally {
      setLoader(dispatch, false);
    }
  };

  const handleModalClose = (event, reason) => {
    if (reason && reason == 'backdropClick') return;
    closeDeleteWhiteboardDialog();
  };

  const ideaboardTabHandleChange = (
    event: React.SyntheticEvent,
    newValue: string
  ) => {
    setIdeaboardTabValue(newValue);
    setSortAsc('created');
    resetValues();
    setSearchText('');
  };

  const openMeeting = (item) => {
    if (item?.past) {
      // only allow past meetings to visit summary page
      navigate(
        `/company/spaces/${currentSpaceSel?.id}/meeting-summary/${item?.id}`
      );
    }
  };

  const removeSearch = () => {
    setSearchText('');
    resetValues();
  };

  const resetValues = () => {
    if (ideaboardTabValue == 'All') {
      setAllMeetboard(searchAllMeetboard);
    } else if (ideaboardTabValue == 'Meetings') {
      setMeetings(searchMeetings);
    } else {
      setWhiteBoards(searchedBoard);
    }
  };

  const callMyTeamDialogClose = (e, rerender = false) => {
    setCallMyTeam(false);
    if (rerender) {
      getListOfIdeaboard();
    }
  };

  const handleClose = (e) => {
    e?.stopPropagation();
    setAnchorEl2(null);
  };

  const displayDateTime = (item) =>
    sortAsc === 'created' ? item?.created_at : item?.updated_at;

  const getIconStyle = (user) =>
    getFullname(user?.first_name, user?.last_name)
      ? { color: '#fff', backgroundColor: `#B388FF`, marginRight: '0.8rem' }
      : { color: '#000', backgroundColor: `#ECEDF0`, marginRight: '0.8rem' };

  //==============================================================================

  return isPrivateSpaceOwner(currentSpaceSel, userData) ? (
    <>
      <div className="idea-board-wrapper " aria-label="space-ideaboard">
        <div className="page-container top-space">
          <div className="bd-header">
            <div className="main-page-header">
              <div className="left-page-header">
                <div className="page-breacrumb">
                  <div className="breadcrumb-wrapper">
                    <ul className="breadcrumb-links">
                      <li>
                        <a
                          onClick={() =>
                            transNavigation(
                              startTransition,
                              navigate,
                              ON_BOARDING_WAITING_PAYMENT
                            )
                          }
                        >
                          Building
                        </a>
                      </li>
                      <li>
                        {isAllowedTo('update', 'space', permissions) ? (
                          <Link to={SPACE_LIST}>Spaces</Link>
                        ) : (
                          'Spaces'
                        )}
                      </li>
                      <li>
                        <Link to={`/company/spaces/${params?.id}/overview`}>
                          {currentSpaceSel?.name}
                        </Link>
                      </li>
                      <li style={{ textTransform: 'none' }}>Ideaboard</li>
                    </ul>
                  </div>
                </div>
              </div>
              <RightPageHeader />
            </div>

            <div className="page-filters-wrapper secondary-header">
              <div className="left-filter">
                <div className="page-title">
                  <h2>{currentSpaceSel?.name} ideaboard</h2>
                  <p className="count">
                    Hub of all your ideas discussion via whiteboard and
                    meetings.
                  </p>
                </div>
              </div>
              <div className="right-filter">
                <Tooltip
                  placement="top"
                  arrow
                  title={'New whiteboard'}
                  classes={{ popper: 'info-tooltip' }}
                >
                  <Button
                    data-testid="wb-btn"
                    variant="outlined"
                    className="xs-btn btn-add-whiteboard"
                    onClick={getWbUrl}
                  >
                    <img src={images.addWhiteboardIconMd} alt="Icon" />
                  </Button>
                </Tooltip>
                <Tooltip
                  placement="top"
                  arrow
                  title={'New meeting'}
                  classes={{ popper: 'info-tooltip' }}
                >
                  <Button
                    variant="outlined"
                    className="xs-btn btn-add-whiteboard"
                    onClick={
                      !isCioOrSpaceMember
                        ? () =>
                            globalError(dispatch, {
                              message:
                                'You are not the owner/member of the space',
                            })
                        : () => setCallMyTeam(true)
                    }
                  >
                    <img src={images.addMeetingIconMd} alt="Icon" />
                  </Button>
                </Tooltip>
              </div>
            </div>
          </div>
          {loading && <LoaderComp maxHeight="32rem" />}
          {!allMeetboard?.length && !searchText && showEmptyState && (
            <div className="no-data-wrapper no-whiteboards">
              <em className="thumb">
                <img src={images.emptyStateIllustration} alt="Spaces Flag" />
              </em>
              <h4 aria-label="no-space">No ideas yet</h4>
              <p>Let's start filling up this board with some fresh ideas!</p>
              <div className="right-filter">
                <Tooltip
                  placement="top"
                  arrow
                  title={'New Document'}
                  classes={{ popper: 'info-tooltip' }}
                >
                  <span>
                    <Button
                      data-testid="wb-btn"
                      disabled={true}
                      variant="outlined"
                      className="xs-btn is-icon-btn btn-add-whiteboard"
                    >
                      <img src={images.addFileTextIconMd} alt="Icon" />
                    </Button>
                  </span>
                </Tooltip>
                <Tooltip
                  placement="top"
                  arrow
                  title={'New whiteboard'}
                  classes={{ popper: 'info-tooltip' }}
                >
                  <Button
                    variant="outlined"
                    className="xs-btn is-icon-btn btn-add-whiteboard"
                    onClick={getWbUrl}
                  >
                    <img src={images.addWhiteboardIconMd} alt="Icon" />
                  </Button>
                </Tooltip>
                <Tooltip
                  placement="top"
                  arrow
                  title={'New meeting'}
                  classes={{ popper: 'info-tooltip' }}
                >
                  <Button
                    variant="outlined"
                    className="xs-btn is-icon-btn btn-add-whiteboard"
                    onClick={
                      !isCioOrSpaceMember
                        ? () =>
                            globalError(dispatch, {
                              message:
                                'You are not the owner/member of the space',
                            })
                        : () => setCallMyTeam(true)
                    }
                  >
                    <img src={images.addMeetingIconMd} alt="Icon" />
                  </Button>
                </Tooltip>
              </div>
            </div>
          )}
          {searchAllMeetboard?.length > 0 && !loading && (
            <div className="inner-section-wrap">
              <div className="page-filters-wrapper">
                <div className="left-filter">
                  <div className="tab-label-wrap">
                    <TabContext
                      data-testid="tab-list"
                      value={ideaboardTabValue}
                    >
                      <Box>
                        <TabList
                          onChange={ideaboardTabHandleChange}
                          aria-label=""
                        >
                          <Tab label="All" value="All" />
                          <Tab label="Meetings" value="Meetings" />
                          <Tab label="Whiteboards" value="Whiteboards" />
                        </TabList>
                      </Box>
                    </TabContext>
                  </div>
                </div>
                <div className="right-filter">
                  <div className="form-group sort-by">
                    <span className="dropdown-text">Sort by</span>
                    <Select
                      MenuProps={{
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'right',
                        },
                        transformOrigin: {
                          vertical: 'top',
                          horizontal: 'right',
                        },
                      }}
                      className="borderless-dropdown sm-height no-label"
                      displayEmpty
                      value={sortAsc}
                      inputProps={{ 'data-testid': 'select-box' }}
                      onChange={(e) => sortData(e?.target?.value)}
                    >
                      <MenuItem className="sorting-menu-item" value="created">
                        Latest
                      </MenuItem>
                      <MenuItem className="sorting-menu-item" value="A-Z">
                        A-Z
                      </MenuItem>
                      <MenuItem className="sorting-menu-item" value="Z-A">
                        Z-A
                      </MenuItem>
                      <MenuItem className="sorting-menu-item" value="updated">
                        Last updated
                      </MenuItem>
                    </Select>
                  </div>
                  <FormControl variant="filled">
                    {/* @ts-ignore */}
                    <FilledInput
                      type="text"
                      className="xs-input search-input-wrap search-whiteboard"
                      placeholder="Search resources"
                      value={searchText}
                      startAdornment={
                        <InputAdornment position="start">
                          <em className="be-search"></em>
                        </InputAdornment>
                      }
                      endAdornment={
                        <InputAdornment
                          position="end"
                          className="search-icon-wrap"
                        >
                          {searchText && (
                            <img
                              data-testid="cross-btn"
                              src={images.iconCrossCircle}
                              alt=""
                              onClick={removeSearch}
                            />
                          )}
                        </InputAdornment>
                      }
                      inputProps={{ 'data-testid': 'search-box' }}
                      onChange={(e) => searchWhiteBoard(e?.target?.value)}
                    />
                  </FormControl>
                </div>
              </div>
              <TabContext value={ideaboardTabValue}>
                <TabPanel value="All">
                  {/* No data found - empty state */}
                  {!allMeetboard?.length && searchText && (
                    <div
                      className="no-data-wrapper no-ideas-yet"
                      aria-label="empty-state"
                    >
                      <em className="thumb">
                        <img
                          src={images.emptyStateIllustration}
                          alt="Spaces Flag"
                        />
                      </em>
                      <h4 aria-label="no-space">No data yet</h4>
                      <p>You haven't had any data yet.</p>
                      {/* <Button variant="contained" className="xs-btn grey-btn btn-new-board" onClick={getWbUrl}><img src={images.addWhiteboardIcon} alt="Icon"/> New board</Button> */}
                    </div>
                  )}
                  {allMeetboard?.map((item, index) => (
                    <div
                      data-testid="all-cards"
                      key={`${item?.id}_${index}`}
                      className="row-container"
                      onClick={
                        item?.class_name == 'Meeting'
                          ? () => openMeeting(item)
                          : () =>
                              navigate(
                                `/company/spaces/${currentSpaceSel?.id}/whiteboard-playground/${item?.id}`
                              )
                      }
                    >
                      <div className="left-content">
                        <span className="icon-wrap">
                          {item?.class_name == 'Meeting' ? (
                            <em className="icon be-meetings"></em>
                          ) : (
                            <img src={images.createWhiteboard} alt="" />
                          )}
                        </span>
                        <div className="truncated-text">
                          <OverflowTooltip
                            text={item?.name}
                            tooltip={item?.name}
                            placement={'right'}
                            className={'label'}
                          />
                          {item?.class_name == 'Meeting' && (
                            <div className="duration">
                              <span>Duration: </span> {item?.duration ?? 60}{' '}
                              minutes
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="center-content table-wrapper has-initials">
                        {item?.class_name == 'Meeting' ? (
                          <>
                            {item?.users?.slice(0, 3)?.map((user, idx) => (
                              <div
                                key={user?.id}
                                className={`initials ${getFullname(user?.first_name, user?.last_name) ? '' : 'grey'}`}
                                style={{ backgroundColor: userColors[idx] }}
                              >
                                <Tooltip
                                  placement="top"
                                  arrow
                                  title={
                                    getFullname(
                                      user?.first_name,
                                      user?.last_name
                                    ) ?? user?.email
                                  }
                                  classes={{ popper: 'info-tooltip' }}
                                >
                                  <span>
                                    {user?.profile_picture
                                      ?.profile_picture_url ? (
                                      <img
                                        src={
                                          user?.profile_picture
                                            ?.profile_picture_url
                                        }
                                        alt=""
                                        className="initials"
                                      />
                                    ) : (
                                      (getInitials(
                                        getFullname(
                                          user?.first_name,
                                          user?.last_name
                                        )
                                      ) ?? <em className="icon be-user"></em>)
                                    )}
                                  </span>
                                </Tooltip>
                              </div>
                            ))}
                            {!item?.users?.length && '-'}
                            {item?.users?.length > 3 && (
                              <div
                                className="initials"
                                style={{ backgroundColor: `#ECEDF0` }}
                                onClick={(event) => {
                                  event.stopPropagation();
                                  setAnchorEl2({
                                    [index]: event.currentTarget,
                                  });
                                }}
                              >
                                +{item?.users?.length - 3}
                                <Menu
                                  id="basic-menu"
                                  className="table-wrapper ideaboard-tooltip"
                                  anchorEl={anchorEl2?.[index]}
                                  open={Boolean(anchorEl2?.[index])}
                                  onClose={handleClose}
                                  MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                  }}
                                  anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                  }}
                                  transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                  }}
                                >
                                  {item?.users?.slice(3)?.map((user) => (
                                    <MenuItem
                                      key={user?.id}
                                      onClick={(e) => e.stopPropagation()}
                                    >
                                      <em
                                        className="initials"
                                        style={getIconStyle(user)}
                                      >
                                        {user?.profile_picture
                                          ?.profile_picture_url ? (
                                          <img
                                            src={
                                              user?.profile_picture
                                                ?.profile_picture_url
                                            }
                                            alt=""
                                            className="initials"
                                          />
                                        ) : (
                                          (getInitials(
                                            getFullname(
                                              user?.first_name,
                                              user?.last_name
                                            )
                                          ) ?? (
                                            <em className="icon be-user"></em>
                                          ))
                                        )}
                                      </em>
                                      <OverflowTooltip
                                        text={
                                          getFullname(
                                            user?.first_name,
                                            user?.last_name
                                          ) ?? user?.email
                                        }
                                        tooltip={
                                          getFullname(
                                            user?.first_name,
                                            user?.last_name
                                          ) ?? user?.email
                                        }
                                        placement={'right'}
                                      />
                                    </MenuItem>
                                  ))}
                                </Menu>
                              </div>
                            )}
                          </>
                        ) : (
                          '-'
                        )}
                      </div>
                      <div className="right-content">
                        <Tooltip
                          placement="top"
                          arrow
                          title={`By ${item?.updated_by_name} on ${formatDate(displayDateTime(item), true)} at ${moment(displayDateTime(item)).utc().format('hh:mm a')}`}
                          classes={{ popper: 'info-tooltip date-tooltip' }}
                        >
                          <span className="date-time">
                            {formatDate(displayDateTime(item), true)}
                          </span>
                        </Tooltip>
                        <OverflowTooltip
                          placement={'top'}
                          text={`By ${item?.updated_by_name}`}
                          tooltip={item?.updated_by_name}
                          className={'auth-name'}
                        />

                        <div className="three-dots">
                          {item?.class_name == 'Whiteboard' &&
                            userData?.id == item?.owner_id && (
                              <>
                                <em
                                  className="icon be-vertical-dots"
                                  aria-label="open-menu"
                                  onClick={(e) => {
                                    (e as any)?.stopPropagation();
                                    handleClick(e, index);
                                  }}
                                ></em>
                                <Menu
                                  id="three-dot-menu-whiteboard"
                                  anchorEl={anchorEl?.[index]}
                                  open={Boolean(anchorEl?.[index])}
                                  onClose={(e) => {
                                    (e as any)?.stopPropagation();
                                    setAnchorEl(null);
                                  }}
                                  MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                  }}
                                  anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                  }}
                                  transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                  }}
                                  className="three-dot-menu"
                                >
                                  <MenuItem
                                    aria-label="del-btn"
                                    className="toggle-menu-item-row"
                                    onClick={(e) => {
                                      (e as any)?.stopPropagation();
                                      setSelectedBoard(item);
                                      setAnchorEl(null);
                                    }}
                                  >
                                    <img src={images.deleteIcon} alt="" />{' '}
                                    Delete Whiteboard
                                  </MenuItem>
                                </Menu>
                              </>
                            )}
                          {item?.class_name === 'Meeting' &&
                            item?.recording_link &&
                            isCioOrSpaceMember && (
                              <>
                                <em
                                  className="icon be-vertical-dots"
                                  aria-label="open-menu"
                                  onClick={(e) => {
                                    (e as any)?.stopPropagation();
                                    handleClick(e, index);
                                  }}
                                ></em>

                                <Menu
                                  id="three-dot-menu-whiteboard"
                                  anchorEl={anchorEl?.[index]}
                                  open={Boolean(anchorEl?.[index])}
                                  onClose={(e) => {
                                    (e as any)?.stopPropagation();
                                    setAnchorEl(null);
                                  }}
                                  MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                  }}
                                  anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                  }}
                                  transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                  }}
                                  className="three-dot-menu"
                                >
                                  <MenuItem
                                    aria-label="del-btn"
                                    className="toggle-menu-item-row"
                                    onClick={(e) => {
                                      (e as any)?.stopPropagation();
                                      setAnchorEl(null);
                                    }}
                                  >
                                    <a
                                      className="d-flex align-item-center"
                                      href={item?.recording_link}
                                      download={true}
                                      style={{ color: 'inherit' }}
                                    >
                                      <em className="download-icon be-download"></em>{' '}
                                      Download recording
                                    </a>
                                  </MenuItem>
                                </Menu>
                              </>
                            )}
                        </div>
                      </div>
                    </div>
                  ))}
                </TabPanel>

                {/* Tab content for Meetings */}
                <TabPanel value="Meetings">
                  {/* No data found - empty state */}
                  {!meetings?.length && !loading && (
                    <div
                      className="no-data-wrapper no-ideas-yet no-meetings"
                      aria-label="empty-state"
                    >
                      <em className="thumb">
                        <img
                          src={images.emptyStateIllustration}
                          alt="Spaces Flag"
                        />
                      </em>
                      <h4 aria-label="no-space">No meetings yet</h4>
                      <p>
                        Let's start filling up this board with some fresh ideas!
                      </p>
                      <Button
                        variant="contained"
                        className="xs-btn  purple-btn"
                        onClick={
                          !isCioOrSpaceMember
                            ? () =>
                                globalError(dispatch, {
                                  message:
                                    'You are not the owner/member of the space',
                                })
                            : () => setCallMyTeam(true)
                        }
                      >
                        {' '}
                        Start a meeting
                      </Button>
                    </div>
                  )}
                  {meetings?.map((meet, index) => (
                    <div
                      data-testid="meetings-card"
                      className="row-container"
                      key={meet?.id}
                    >
                      <div
                        className="left-content"
                        onClick={() => openMeeting(meet)}
                      >
                        <span className="icon-wrap">
                          <em className="icon be-meetings"></em>
                        </span>
                        <span className="label truncated-text">
                          <OverflowTooltip
                            text={meet?.name}
                            tooltip={meet?.name}
                            placement={'right'}
                          />
                          <div className="duration">
                            <span>Duration: </span> {meet?.duration ?? 60}{' '}
                            minutes
                          </div>
                        </span>
                      </div>
                      <div className="center-content table-wrapper has-initials">
                        {meet?.class_name == 'Meeting' ? (
                          <>
                            {meet?.users?.slice(0, 3)?.map((user, idx) => (
                              <div
                                key={user?.id}
                                className={`initials ${getFullname(user?.first_name, user?.last_name) ? '' : 'grey'}`}
                                style={{ backgroundColor: userColors[idx] }}
                              >
                                <Tooltip
                                  placement="top"
                                  arrow
                                  title={
                                    getFullname(
                                      user?.first_name,
                                      user?.last_name
                                    ) ?? user?.email
                                  }
                                  classes={{ popper: 'info-tooltip' }}
                                >
                                  <span>
                                    {user?.profile_picture
                                      ?.profile_picture_url ? (
                                      <img
                                        src={
                                          user?.profile_picture
                                            ?.profile_picture_url
                                        }
                                        alt=""
                                        className="initials"
                                      />
                                    ) : (
                                      (getInitials(
                                        getFullname(
                                          user?.first_name,
                                          user?.last_name
                                        )
                                      ) ?? <em className="icon be-user"></em>)
                                    )}
                                  </span>
                                </Tooltip>
                              </div>
                            ))}
                            {!meet?.users?.length && '-'}
                            {meet?.users?.length > 3 && (
                              <div
                                className="initials"
                                style={{ backgroundColor: `#ECEDF0` }}
                                onClick={(event) => {
                                  event.stopPropagation();
                                  setAnchorEl2({
                                    [index]: event.currentTarget,
                                  });
                                }}
                              >
                                +{meet?.users?.length - 3}
                                <Menu
                                  id="basic-menu"
                                  className="table-wrapper ideaboard-tooltip"
                                  anchorEl={anchorEl2?.[index]}
                                  open={Boolean(anchorEl2?.[index])}
                                  onClose={handleClose}
                                  MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                  }}
                                  anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                  }}
                                  transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                  }}
                                >
                                  {meet?.users?.slice(3)?.map((user) => (
                                    <MenuItem
                                      key={user?.id}
                                      onClick={(e) => e.stopPropagation()}
                                    >
                                      {/* userColors[Array(meet?.users?.length).fill([0, 1, 2, 3]).flat()[index]] */}
                                      <em
                                        className="initials"
                                        style={getIconStyle(user)}
                                      >
                                        {user?.profile_picture
                                          ?.profile_picture_url ? (
                                          <img
                                            src={
                                              user?.profile_picture
                                                ?.profile_picture_url
                                            }
                                            alt=""
                                            className="initials"
                                          />
                                        ) : (
                                          (getInitials(
                                            getFullname(
                                              user?.first_name,
                                              user?.last_name
                                            )
                                          ) ?? (
                                            <em className="icon be-user"></em>
                                          ))
                                        )}
                                      </em>
                                      <OverflowTooltip
                                        text={
                                          getFullname(
                                            user?.first_name,
                                            user?.last_name
                                          ) ?? user?.email
                                        }
                                        tooltip={
                                          getFullname(
                                            user?.first_name,
                                            user?.last_name
                                          ) ?? user?.email
                                        }
                                        placement={'right'}
                                      />
                                    </MenuItem>
                                  ))}
                                </Menu>
                              </div>
                            )}
                          </>
                        ) : (
                          '-'
                        )}
                      </div>
                      <div className="right-content">
                        <Tooltip
                          placement="top"
                          arrow
                          title={`By ${meet?.updated_by_name} on ${formatDate(displayDateTime(meet), true)} at ${moment(displayDateTime(meet)).utc().format('hh:mm a')}`}
                          classes={{ popper: 'info-tooltip date-tooltip' }}
                        >
                          <span className="date-time">
                            {formatDate(displayDateTime(meet), true)}
                          </span>
                        </Tooltip>
                        <OverflowTooltip
                          placement={'top'}
                          text={`By ${meet?.updated_by_name}`}
                          tooltip={meet?.updated_by_name}
                          className={'auth-name'}
                        />

                        {isCioOrSpaceMember && (
                          <div className="three-dots">
                            {meet?.recording_link && (
                              <>
                                <em
                                  className="icon be-vertical-dots"
                                  aria-label="open-menu"
                                  onClick={(e) => {
                                    (e as any)?.stopPropagation();
                                    handleClick(e, index);
                                  }}
                                ></em>
                                <Menu
                                  id="three-dot-menu-whiteboard"
                                  anchorEl={anchorEl?.[index]}
                                  open={Boolean(anchorEl?.[index])}
                                  onClose={(e) => {
                                    (e as any)?.stopPropagation();
                                    setAnchorEl(null);
                                  }}
                                  MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                  }}
                                  anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                  }}
                                  transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                  }}
                                  className="three-dot-menu"
                                >
                                  <MenuItem
                                    aria-label="del-btn"
                                    className="toggle-menu-item-row"
                                    onClick={(e) => {
                                      (e as any)?.stopPropagation();
                                      setAnchorEl(null);
                                    }}
                                  >
                                    <a
                                      className="d-flex align-item-center"
                                      href={meet?.recording_link}
                                      download={true}
                                      style={{ color: 'inherit' }}
                                    >
                                      <em className="download-icon be-download"></em>{' '}
                                      Download recording
                                    </a>
                                  </MenuItem>
                                </Menu>
                              </>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </TabPanel>

                {/* Tab content for Whiteboards */}
                <TabPanel value="Whiteboards">
                  {/* No data found - empty state */}
                  {!whiteBoards?.length && !loading && (
                    <div className="no-data-wrapper no-whiteboards">
                      <em className="thumb">
                        <img
                          src={images.emptyStateIllustration}
                          alt="Spaces Flag"
                        />
                      </em>
                      <h4 aria-label="no-space">No whiteboards yet</h4>
                      <p>
                        Let's start filling up this board with some fresh ideas!
                      </p>
                      <Button
                        onClick={getWbUrl}
                        variant="contained"
                        className="xs-btn grey-btn"
                      >
                        Create a whiteboard
                      </Button>
                    </div>
                  )}
                  {whiteBoards?.map((board, index) => (
                    <div
                      data-testid="whiteboard-card"
                      className="row-container"
                      key={board?.id}
                      onClick={() =>
                        navigate(
                          `/company/spaces/${currentSpaceSel?.id}/whiteboard-playground/${board?.id}`
                        )
                      }
                    >
                      <div className="left-content">
                        <span className="icon-wrap">
                          <img src={images.createWhiteboard} alt="" />
                          {/* <em className="icon be-meetings"></em> */}
                        </span>
                        <span className="label truncated-text">
                          <OverflowTooltip
                            text={board?.name}
                            tooltip={board?.name}
                            placement={'right'}
                          />
                        </span>
                      </div>
                      <div className="right-content">
                        <Tooltip
                          placement="top"
                          arrow
                          title={`By ${board?.updated_by_name} on ${formatDate(board?.created_at, true)} at ${moment(board?.created_at).utc().format('hh:mm a')}`}
                          classes={{ popper: 'info-tooltip date-tooltip' }}
                        >
                          <span className="date-time">
                            {formatDate(board?.created_at, true)}
                          </span>
                        </Tooltip>
                        <OverflowTooltip
                          placement={'top'}
                          text={`By ${board?.updated_by_name}`}
                          tooltip={board?.updated_by_name}
                          className={'auth-name'}
                        />
                        <div className="three-dots">
                          {userData?.id == board?.owner_id && (
                            <em
                              className="icon be-vertical-dots"
                              aria-label="open-menu"
                              onClick={(e) => {
                                (e as any)?.stopPropagation();
                                handleClick(e, index);
                              }}
                            ></em>
                          )}
                          <Menu
                            id="three-dot-menu-whiteboard"
                            anchorEl={anchorEl?.[index]}
                            open={Boolean(anchorEl?.[index])}
                            onClose={(e) => {
                              (e as any)?.stopPropagation();
                              setAnchorEl(null);
                            }}
                            MenuListProps={{
                              'aria-labelledby': 'basic-button',
                            }}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'right',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'right',
                            }}
                            className="three-dot-menu"
                          >
                            <MenuItem
                              aria-label="del-btn"
                              className="toggle-menu-item-row"
                              onClick={(e) => {
                                (e as any)?.stopPropagation();
                                setSelectedBoard(board);
                                setAnchorEl(null);
                              }}
                            >
                              <img src={images.deleteIcon} alt="" /> Delete
                              Whiteboard
                            </MenuItem>
                          </Menu>
                        </div>
                      </div>
                    </div>
                  ))}
                </TabPanel>
              </TabContext>
            </div>
          )}
          {/* ============================================================================================= */}

          {/* {!frameUrl && <div className="inner-section-wrap">
            <div className="page-filters-wrapper">
              <div className="left-filter">
                <span className="count"><img src={images.createWhiteboard} alt="Icon"/> Whiteboards</span>
              </div>
              <div className="right-filter">
                <div className="form-group sort-by">
                  <span className="dropdown-text">Sort by</span>
                  <Select
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right"
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "right"
                      }
                    }}
                    className="borderless-dropdown sm-height no-label"
                    displayEmpty
                    value={sortAsc}
                    onChange={e => sortWhiteBoard(e?.target?.value)}
                  >
                    <MenuItem className='sorting-menu-item' value="created">Latest</MenuItem>
                    <MenuItem className='sorting-menu-item' value="A-Z">A-Z</MenuItem>
                    <MenuItem className='sorting-menu-item' value="Z-A">Z-A</MenuItem>
                    <MenuItem className='sorting-menu-item' value="updated">Last updated</MenuItem>
                  </Select>
                </div>
                <FormControl variant="filled">

                  <FilledInput type="text" variant="filled" className="xs-input search-input-wrap search-whiteboard" placeholder='Search '
                    value={searchText}
                    startAdornment={
                      <InputAdornment position="start"><em className='be-search'></em></InputAdornment>
                    }
                    endAdornment={
                      <InputAdornment position="end" className='search-icon-wrap'>
                        {searchText && <img src={images.iconCrossCircle} alt="" onClick={() => {setSearchText(''); setWhiteBoards(searchedBoard)}}/>}
                      </InputAdornment>
                    }
                    onChange={(e) => searchWhiteBoard(e?.target?.value)}
                  />
                </FormControl>
              </div>
            </div>


            {!whiteBoards?.length && !loading && <div className="no-data-wrapper no-ideas-yet" aria-label="empty-state">
              <em className="thumb"><img src={images.emptyStateIllustration} alt="Spaces Flag" /></em>
              <h4 aria-label="no-space">No ideas yet</h4>
              <p>You haven't had any idea session yet.</p>
              <Button variant="contained" className="xs-btn grey-btn btn-new-board" onClick={getWbUrl}><img src={images.addWhiteboardIcon} alt="Icon"/> New board</Button>
            </div>}

            <div className="table-wrapper table-responsive space-list-table">
              <TableContainer>
                  <Table>
                    <TableBody>
                      { loading && <tr><td><img src={(images as any).buAnim} alt="B" style={{ position: 'fixed', top: '50%', left: '50%', height: '80px', width:'80px', transform: 'translate(-50%, -50%)' }}/></td></tr> }
                      {whiteBoards?.map((board, idx) => <TableRow key={board?.id}>
                        <TableCell className="cr-pointer" onClick={() => {openIframe(board?.url); setNewWb(board?.name)}}><strong>{board?.name}</strong></TableCell>
                        <TableCell>Updated {moment(board?.updated_at).format('DD.MM.YY')}</TableCell>
                        <TableCell>By {board?.updated_by_name}</TableCell>
                        <TableCell>
                          <div className='three-dots' aria-label="menu">
                            <em className='icon be-vertical-dots' aria-label="open-menu" onClick={(e) => handleMenuClick(idx, e)}></em>
                            <Menu
                              id="basic-menu"
                              anchorEl={anchorEl?.[idx]}
                              open={Boolean(anchorEl?.[idx])}
                              onClose={handleClose}
                              MenuListProps={{
                              'aria-labelledby': 'basic-button',
                              }}
                              anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'right',
                              }}
                              transformOrigin={{
                              vertical: 'top',
                              horizontal: 'right',
                              }}
                              className="three-dot-menu"
                            >
                                <MenuItem aria-label="del-btn" className='toggle-menu-item-row' onClick={() => deleteDialog(board)}><img src={images.deleteIcon} alt="" /> Delete</MenuItem>
                            </Menu>
                          </div>
                        </TableCell>
                      </TableRow>)}
                    </TableBody>
                  </Table>
              </TableContainer>
            </div>
          </div>} */}
        </div>
      </div>

      {/* {frameUrl && <iframe aria-label="iframe-label" title="whiteboard application" src={frameUrl} className="wb-integration-iframe" />} */}

      {/* Delete Whiteboard modal start */}
      {selectedBoard && (
        <Dialog open={Boolean(selectedBoard)} onClose={handleModalClose}>
          <DialogContent
            className="delete-whiteboard-modal"
            aria-label="delete-dialog"
          >
            <div className="modal-body">
              <div className="modal-title">
                <h2>Delete Whiteboard {selectedBoard?.name}</h2>
              </div>
              <p>
                Are you sure you want to delete this whiteboard? Once deleted it
                cannot be retrieved.
              </p>
            </div>
          </DialogContent>
          <DialogActions className="fixed-footer-wrap">
            <div className="fixed-full-footer">
              <Button
                variant="contained"
                className="grey-btn sm-btn"
                onClick={closeDeleteWhiteboardDialog}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                className="sm-btn green-btn"
                aria-label="delete-btn"
                onClick={deleteBoard}
              >
                Confirm delete
              </Button>
            </div>
          </DialogActions>
        </Dialog>
      )}
      {/* Delete Whiteboard modal End */}

      {callMyTeam && (
        <AddMemberToBmeetDialog
          {...{
            callMyTeam,
            callMyTeamDialogClose,
            selectedMeeting: null,
            isFromIdeaboard: true,
          }}
        />
      )}
    </>
  ) : (
    <UnauthorizedAccess />
  );
}

export default SpaceIdeaboard;
